<template>
  <!-- ROW 1 -->
  <div class="contained-example-container">
    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <statistics-card-line
        icon-right
        :icon="icon"
        :statistic="count"
        :statisticTitle="title"
        :chartData="chartData.series"
        :chartOptions="chartData.chartOptions"
      />
      <!-- <statistics-card-line
        icon="UserCheckIcon"
        icon-right
        statistic="659.8k"
        statisticTitle="Active Users"
        :chartData="activeUsers"
        color="success"
      ></statistics-card-line>-->
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CardLineV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      count: 0,
      icon: '',
      chartData: {
        series: [
          {
            name: '',
            data: []
          }
        ],
        chartOptions: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 5,
              left: 0,
              blur: 4,
              opacity: 0.1
            },
            toolbar: {
              show: false
            },
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: 5,
            curve: 'smooth'
          },
          xaxis: {
            type: 'numeric'
          },
          colors: ['#FF9F43'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: ['#ffc085'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 75, 100, 100]
            }
          },
          markers: {
            size: 0,
            hover: {
              size: 5
            }
          },
          tooltip: {
            x: { show: false }
          }
        }
      }
    }
  },
  components: {
    StatisticsCardLine
  },
  watch: {
    services() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    async load(report) {
      this.icon = this.report._extra.icon
      const found = this.report._extra.lineLabel.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        this.chartData.series[0].name = found.text
      } else {
        this.chartData.series[0].name = ''
      }

      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        if (result.data[0][0].count != null) {
          this.count = result.data[0][0].count
        } else {
          this.count = 0
        }
        let info = []
        result.data[1].forEach(element => {
          info.push(element.count)
        })
        this.chartData.series[0].data = info
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>
